@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 640px) {
  .coinportimg:hover {
    transform: translateY(-74%);
    transition: transform 5s ease-in-out 0s;
  }
}

@media screen and (min-width: 640px) {
  .coinportimg:hover {
    transform: translateY(-77%);
    transition: transform 5s ease-in-out 0s;
  }
}

@media screen and (min-width: 768px) {
  .coinportimg:hover {
    transform: translateY(-81%);
    transition: transform 5s ease-in-out 0s;
  }
}

@media screen and (min-width: 1024px) {
  .coinportimg:hover {
    transform: translateY(-82%);
    transition: transform 5s ease-in-out 0s;
  }
}

@media screen and (min-width: 1280px) {
  .coinportimg:hover {
    transform: translateY(-61%);
    transition: transform 5s ease-in-out 0s;
  }
}